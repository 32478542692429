import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */
import './styles/app.css';
import './styles/inc/header.css';
import './styles/inc/base.css';
import './styles/responsive/inc/header.css';
import './styles/inc/footer.css';
import './styles/inc/normalize.css';

